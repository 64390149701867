const featureTests = {
    waapi: () => Object.hasOwnProperty.call(Element.prototype, "animate"),
};
const results = {};
const supports = {};
/**
 * Generate features tests that cache their results.
 */
for (const key in featureTests) {
    supports[key] = () => {
        if (results[key] === undefined)
            results[key] = featureTests[key]();
        return results[key];
    };
}

export { supports };
